<template>
  <div>
    <div class="card bg-white p-2 rounded-md mt-2">
      <DataTable :value="dataTutelas"
                 class="p-datatable-customers p-datatable-sm text-sm"
                 showGridlines
                 dataKey="id"
                 v-model:filters="filters1"
                 filterDisplay="menu"
                 responsiveLayout="scroll"
                 @sort="sort"
                 removableSort
      >
        <template #header>
          <div class="flex justify-end">
            <div class="flex justify-between gap-4">
              <div class="flex">
                <Button type="button"
                        icon="pi pi-filter-slash text-sm"
                        label="Limpiar filtros"
                        class="p-button-outlined text-sm font-medium mr-2"
                        @click="clearFilter1()"
                />
                <Button type="button"
                        icon="pi pi-filter-slash text-sm"
                        label="Exportar XLS"
                        class="p-button-outlined text-sm font-medium p-button-success"
                        @click="downloadXLS()"
                />
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          No existen tutelas registradas
        </template>
        <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="id" header="Id">
          <template #body="{data}">
            <Button :label="`${data.id}`"
                    class="p-button-link text-black"
                    style="font-size: 0.75rem"
                    @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas.ver',params: { id: data.id }})"
            />
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>
        <Column field="accionanteFullname" header="Accionante / Usuario" style="min-width:10rem">
          <template #body="{data}">
            <p class="text-xs">
              {{ data.accionanteFullname }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>
        <Column field="accionanteNumeroIdentificacion" header="Documento" style="min-width:10rem">
          <template #body="{data}">
            <p class="text-xs">
              {{ data.accionanteDocTipoId + ': ' + data.accionanteNumeroIdentificacion }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>
        <Column field="numRadicado" header="Radicado" style="min-width:10rem">
          <template #body="{data}">
            <p class="text-xs">
              {{ data.numRadicado }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>
        <Column header="Notificación"
                filterField="fechaRecepcion"
                sortField="fechaRecepcion"
                style="min-width:10rem"
                sortable
        >
          <template #body="{data}">
            <p class="text-xs">
              {{ $h.formatDate(data.fechaRecepcion, 'YYYY/MM/DD') }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <input v-model="filterModel.value" placeholder="YYYY-MM-DD" type="date" class="p-inputtext">
          </template>
        </Column>
        <Column header="Vencimiento"
                filterField="fechaVencimiento"
                style="min-width:10rem"
        >
          <template #body="{data}">
            <p class="text-xs">
              {{ $h.formatDate(data.fechaVencimiento, 'YYYY/MM/DD') }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <input v-model="filterModel.value" placeholder="YYYY-MM-DD" type="date" class="p-inputtext">
          </template>
        </Column>

        <Column header="Medida Provisional"
                filterField="provisonalidad"
                style="min-width:10rem"
        >
          <template #body="{data}">
            <div v-if="data.provisonalidad" class="w-full">
              <p class="text-xs">
                {{ $h.formatDate(data.provisonalidadFecha, 'YYYY/MM/DD HH:mm') }}
              </p>
            </div>
            <div v-else>
              No tiene
            </div>
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="tipoProvisionalidad" optionLabel="name"
                      class="p-column-filter" optionValue="code" placeholder="Tiene?"/>
          </template>
        </Column>

        <Column header="Juzgado"
                field="juzgadoNombre"
                filterField="juzgadoNombre"
                style="min-width:10rem"
        >
          <template #body="{data}">
            <p class="text-xs">
              {{ data.juzgadoNombre }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>

        <Column header="Estado"
                field="estado"
                filterField="respuesta"
                style="min-width:8rem; text-align: center"
        >
          <template #body="{data}">
            <div>
              <button
                v-if="!data.respuesta"
                @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas.ver',params: { id: data.id }})"
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md text-xs"
                v-tooltip.bottom="{value:
                `${dayjs(data.fechaVencimiento) > dayjs() ? 'Vence' : 'Vencido'} ${dayjs(data.fechaVencimiento).locale('es').fromNow()}
                `
                }"
                :class="indicadorEstadoColor(data.fechaVencimiento, data.respuesta)">
                {{ indicadorEstadoNombre(data.fechaVencimiento, data.respuesta) }}
              </button>
            </div>
            <!-- {{ dayjs(data.fechaVencimiento).locale('es').fromNow() }} -->
            <div>
              <button
                v-if="data.respuesta"
                @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas.ver',params: { id: data.id }})"
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md text-xs"
                v-tooltip.bottom="'Respondido'"
                :class="indicadorEstadoColor(data.fechaVencimiento, data.respuesta)">
                {{ indicadorEstadoNombre(data.fechaVencimiento, data.respuesta) }}
              </button>
            </div>
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="estados" optionLabel="name" class="p-column-filter"
                      optionValue="code" placeholder="Estado?"/>
          </template>
        </Column>

        <Column header="Responsable"
                field="responsablePersonaFull_name"
                style="min-width:10rem"
        >
          <template #body="{data}">
            <p class="text-xs">
              {{ data.responsablePersonaFull_name }}
            </p>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
          </template>
        </Column>

        <Column
          header="Incidentes"
          style="min-width:9rem"
        >
          <template #body="{data}">
            <div class="flex justify-center">
              <button
                v-if="data.incidenciaVencimiento === 'respondido'"
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md bg-green-500"
                @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas.ver.incidentes',params: { id: data.id }})"
                v-tooltip.bottom="{value:
                `Respondido`
                }">
                Respondido
              </button>
<!--              <button-->
<!--                v-if="data.incidenciaVencimiento === 'respondido'"-->
<!--                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md text-xs"-->
<!--                v-tooltip.bottom="{value:-->
<!--                `Respondido`-->
<!--                }"-->
<!--                :class="indicadorEstadoColor(data.incidenciaVencimiento)">-->
<!--                {{ indicadorEstadoNombre(data.incidenciaVencimiento) }}-->
<!--              </button>-->
              <button
                v-else-if="data.incidenciaVencimiento"
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md text-xs"
                @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas.ver.incidentes',params: { id: data.id }})"
                v-tooltip.bottom="{value:
                `${dayjs(data.incidenciaVencimiento) > dayjs() ? 'Vence' : 'Vencido'} ${dayjs(data.incidenciaVencimiento).fromNow()}
                `
                }"
                :class="indicadorEstadoColor(data.incidenciaVencimiento)">
                {{ indicadorEstadoNombre(data.incidenciaVencimiento) }}
              </button>
              <button
                v-else
                class="font-medium p-1.5 text-white w-3/4 text-center rounded-md bg-gray-500"
                v-tooltip.bottom="{value:
                `Sin pendientes`
                }">
                Sin pendientes
              </button>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="offset"
                 :rows="limit"
                 :totalRecords="totalItemsCount"
                 :rowsPerPageOptions="[2,10,20,30]"
                 @page="onPage($event)"
      />
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch } from 'vue'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import XlsService from '@/apps/pharmasan/juridica/procesosJuridicos/services/xls.service'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)
export default {
  name: 'TablaTutelas',
  setup () {
    const _XlsService = new XlsService()
    const tipoProvisionalidad = [
      { name: 'Si', code: 'true' },
      { name: 'No', code: 'false' }
    ]
    const estados = [
      { name: 'Respondidas', code: 'true' },
      { name: 'Sin responder', code: 'false' }
    ]
    onMounted(() => {
      storeTutela.dispatch('getPaginate', params.value)
      dayjs.extend(duration)
      dayjs.extend(relativeTime)
      dayjs().locale('es')
    })
    /** computed **/
    const dataTutelas = computed(
      () => storeTutela.getters.tutelas.rows
    )
    const totalItemsCount = computed(
      () => storeTutela.getters.tutelas.count
    )
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filters: filters1.value
      }
    })
    /** data **/
    const filters1 = ref({
      // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.EQUALS
          }
        ]
      },
      accionanteFullname: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      accionanteNumeroIdentificacion: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      numRadicado: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      fechaRecepcion: {
        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
      },
      fechaVencimiento: {
        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
      },
      juzgadoNombre: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      responsablePersonaFull_name: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      provisonalidad: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.EQUALS
          }
        ]
      },
      respuesta: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.EQUALS
          }
        ]
      }
    })
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const contador = ref()
    /** methods **/
    const clearFilter1 = () => {
      initFilters1()
    }
    const initFilters1 = () => {
      filters1.value = {
        id: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.EQUALS
            }
          ]
        },
        accionanteFullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        accionanteNumeroIdentificacion: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        numRadicado: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        fechaRecepcion: {
          operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }]
        },
        fechaVencimiento: {
          operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
        },
        juzgadoNombre: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        responsablePersonaFull_name: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        provisonalidad: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        respuesta: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.EQUALS
            }
          ]
        }
      }
    }
    const sort = (evt) => {
      console.log({ evt })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      storeTutela.dispatch('getPaginate', params.value)
    }
    const indicadorEstadoColor = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'atiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'proximo-vencer'
        } else {
          return 'vencidos'
        }
      }
    }
    const indicadorEstadoNombre = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'Respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'A tiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'A vencer'
        } else {
          return 'Vencido'
        }
      }
    }
    const downloadXLS = () => {
      _XlsService.downloadTutelas().then(res => {
        window.open(res, '_blank')
      })
    }
    const contando = setInterval((vencimiento) => {
      const inicio = dayjs()
      const fin = dayjs(vencimiento)
      return dayjs.duration(fin - inicio).$d.$d
    }, 1000)
    /** whatch **/
    watch(filters1, (a, b) => {
      offset.value = 0
      page.value = 0
      limit.value = 10
      storeTutela.dispatch('getPaginate', params.value)
    })
    /** return **/
    return {
      dataTutelas,
      totalItemsCount,
      offset,
      limit,
      filters1,
      clearFilter1,
      initFilters1,
      sort,
      onPage,
      params,
      indicadorEstadoColor,
      indicadorEstadoNombre,
      downloadXLS,
      contador,
      contando,
      dayjs,
      duration,
      tipoProvisionalidad,
      estados
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

.respondida {
  background-color: #1149d7 !important;
  color: white;
}

.atiempo {
  background-color: #58AB30 !important;
  color: white;
}

.proximo-vencer {
  background-color: #FA901B !important;
}

.vencidos {
  background-color: #FA2311 !important;
}
</style>
