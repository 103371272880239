import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class XlsService {
  async downloadTutelas () {
    const url = `${baseUrl}/xls/tutelas`
    return http.getUri({ url })
  }

  async downloadProcesosAdministrativos () {
    const url = `${baseUrl}/xls/procesos-administrativos`
    return http.getUri({ url })
  }
}
